import styled from "@emotion/styled"
import IconArrowRight from "../../../assets/icons/arrow-right.svg"

export const Navigation = styled.ul`
  height: ${(props) => props.isFullHeight && "100%"};
  display: inline-flex;
  flex-direction: ${(props) => props.isVertical && "column"};
  list-style-type: none;
  margin-bottom: 0;
  margin-left: 0;

  li {
    display: ${(props) => props.isFullHeight && "flex"};
    align-items: ${(props) => props.isFullHeight && "center"};
    margin-right: ${(props) => !props.isVertical && "16px"};
    margin-bottom: ${(props) => (props.isVertical ? "4px" : 0)};
    margin-left: ${(props) => props.isVertical && "0"};

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const NavigationHeading = styled.p`
  line-height: 1;
  margin-bottom: 16px;
  display: flex;

  &,
  a {
    color: currentColor;
    font-size: 14px;
    text-decoration: none;
  }

  &:not(:first-of-type) {
    margin-top: ${(props) =>
    !props.noMarginTop ? (props.isVertical ? `48px` : `60px`) : `0`};
  }
`

export const NavigationItem = styled.li`
  position: ${(props) => props.hasArrow && "relative"};
  opacity: ${(props) => props.isDimmed && 0.5};
  transition: opacity 0.2s;

  a {
    display: block;
    color: currentColor;
    font-size: 14px;
    text-decoration: none;

    &::before {
      content: ${(props) => props.hasArrow && `""`};
      display: block;
      width: 9px;
      height: 8px;
      background-image: ${`url("${IconArrowRight}")`};
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      left: calc(100% + 12px);
      transform: translateY(-50%);
    }

    svg {
      font-size: 16px;
    }
  }

  span {
    cursor: pointer;
    line-height: 1;
  }
`

export const NavigationMega = styled.div`
  display: flex;
  width: 100%;
  background-color: #f7f5f6;
  position: absolute;
  top: 100%;
  left: 0;
  border-top: 1px solid #e5e3e4;
  opacity: ${(props) => (props.isOpen ? `1` : `0`)};
  visibility: ${(props) => (props.isOpen ? `visible` : `hidden`)};
  transition: opacity 0.2s, visibility 0.2s;
  min-height: ${(props) => (props.sameHeight ? `${props.sameHeight}px` : `0`)};
`

export const NavigationMegaColumn = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: ${(props) => (props.size ? props.size * (100 / 12) + "%" : "100%")};
  flex: 0 0 ${(props) => (props.size ? props.size * (100 / 12) + "%" : "100%")};
  border-right: 1px solid #e5e3e4;
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 26px;
  padding-left: 24px;

  &:first-of-type {
    padding-left: 16px;
  }

  &:last-of-type {
    border-right: 0;
    padding-right: 16px;
  }

  ${Navigation} {
    margin-bottom: 65px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

export const NavigationMegaPhotos = styled.div`
  display: flex;
  margin-right: -12px;
  margin-left: -12px;
  width: 100%;
`

export const NavigationMegaPhotosColumn = styled.div`
  flex-basis: 100%;
  padding-right: 12px;
  padding-left: 12px;

  a {
    position: relative;
    border-bottom: none;
  }

  img {
    display: block;
  }
`

export const NavigationMegaPlaceholder = styled.div`
  visibility: hidden;
  opacity: 0;
  z-index: -1;
`
